import React, { useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { bool } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import { Box, Text, Center, Flex, Divider, useToast, Button } from '@chakra-ui/react';

import QRCode from 'react-qr-code';
import loadable from '@loadable/component';

import { getShiftTime } from '@hooks/useShiftTime';
import useGuestInfo from '@/hooks/useGuestInfo';
import useLang from '@/hooks/useLang';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

import { THE_BRIDE, WEDDING_DATE } from '@/constants';
import { IMG_QR_COVER } from '@/constants/assets';

import QRButton from '../QRCodeButton';
import txt from './locales';

const LazyExportPNG = loadable(() =>
  import(/* webpackChunkName: "invitato-export-png" */ './ExportPNG'),
);

/**
 * Function to render QRCard version 2
 * Support:
 * - Download QR as PNG
 * - Open QR in new page
 */
function QRCardSection({ isDirectLink, ...rest }) {
  const toast = useToast();
  const location = useLocation();
  const isAutoDownload = getQueryValue(location, 'autoDownload') == '1';

  const lang = useLang();
  const ticketRef = useRef(null);
  const isShowToaster = useRef(false);
  const { code, name, desc, shift } = useGuestInfo();

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !isDirectLink &&
      !isShowToaster.current &&
      !isAutoDownload
    ) {
      isShowToaster.current = true;
      toast({
        isClosable: true,
        status: 'warning',
        duration: null,
        description: (
          <Text fontSize="sm">
            <span dangerouslySetInnerHTML={{__html: txt.darkmodeDesc[lang]}} />
          </Text>
        ),
      });
    }
  }, []);

  return (
    <Box padding="20px">
      <div ref={ticketRef}>
        <Box bgColor="bgSecondary" padding="6px" {...rest}>
          <Box bgColor="white" padding="6px">
            {/* QRCODE COVER SECTION */}
            <Box
              bgImage={`url(${IMG_QR_COVER})`}
              width="100%"
              height="200px"
              bgSize="cover"
              bgPos="center"
            >
              <Center height="200px" fontFamily="jost">
                <Box color="white" textTransform="uppercase" textAlign="center" textShadow="1px 1px 1px black">
                  <Text fontSize="xs" letterSpacing="2px" marginTop="12px">
                    {txt.title[lang]}
                  </Text>
                  <Text fontSize="xl" letterSpacing="6px" margin="4px 0">
                    {THE_BRIDE}
                  </Text>
                  <Text fontSize="xs" letterSpacing="4px" fontWeight="light">
                    {WEDDING_DATE}
                  </Text>
                </Box>
              </Center>
            </Box>
            {/* DETAIL QRCODE */}
            <Flex fontFamily="jost" fontSize="sm" padding="12px 4px" justifyContent="space-between">
              <Box flex="1.3">
                <Text fontWeight="light">{txt.to[lang]}</Text>
                <Text fontSize="xs">{name}</Text>

                <Text marginTop="8px" fontWeight="light">
                  {txt.desc[lang]}
                </Text>
                <Text fontSize="xs">{`${desc}`}</Text>
                <Text fontSize="xs">{`Shift ${shift} (${getShiftTime(shift)})`}</Text>

                <Text marginTop="8px" fontWeight="light">
                  {txt.important[lang]}
                </Text>
                <Text fontSize="xs">{txt.importantDesc[lang]}</Text>
              </Box>
              <Box flex="1">
                <Box float="right">
                  <QRCode value={code} size={125} />
                </Box>
              </Box>
            </Flex>
          </Box>
          <Divider opacity="1" height="2px" bgColor="bgSecondary" border="none" />
          {/* Copyright Section */}
          <Box fontFamily="jost" textAlign="center" bgColor="white" padding="8px 0">
            <Text fontSize="xs">Organized & Generated by Invitato</Text>
            <Text fontSize="xs">{`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
          </Box>
        </Box>
      </div>
      <Box marginBottom="16px">
        <Text fontSize="sm" textAlign="center" margin="8px">
          <span dangerouslySetInnerHTML={{ __html: txt.downloadDesc[lang] }} />
        </Text>
        <Center>
          {isDirectLink ? (
            <QRButton
              size="sm"
              bgColor="bgSecondary"
              color="white"
              text="Download E-ticket"
              customQueries="&autoDownload=1"
            />
          ) : (
            <LazyExportPNG
              bgColor="bgSecondary"
              color="white"
              componentRef={ticketRef}
              autoDownload={isAutoDownload}
            />
          )}
        </Center>
        {!isDirectLink && (
          <Center>
            <Button
              variant="outline"
              marginTop="16px"
              leftIcon={<BiArrowBack />}
              type="button"
              size="sm"
              fontWeight="normal"
              color="bgSecondaryColor"
              borderColor="black"
              onClick={() => navigate(-1)}
            >
              {txt.back[lang]}
            </Button>
          </Center>
        )}
      </Box>
    </Box>
  );
}

QRCardSection.propTypes = {
  isDirectLink: bool,
};

QRCardSection.defaultProps = {
  isDirectLink: true,
};

export default React.memo(QRCardSection);