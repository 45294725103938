import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { node } from 'prop-types';
import { Box } from '@chakra-ui/react';

import { SEO_IMAGE, SEO_URL, SEO_DESCRIPTION, SEO_TITLE } from '@/constants';
import { DEFAULT_LANGUAGE } from '@/constants/feature-flags';
import useIsDesktopView from '@hooks/useIsDesktopView';

import ImgFav from './assets/favicon.png';
import DesktopView from './LeftContent';

import { styDesktopView } from './styles';
import 'react-image-lightbox/style.css';
import './assets/styles.css';

function MainLayout({ children }) {
  const { isDesktopView } = useIsDesktopView();

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: DEFAULT_LANGUAGE, translate: 'no' }}>
        <title>{SEO_TITLE}</title>

        <link rel="icon" type="image/png" href={ImgFav} />

        <meta property="og:title" content={SEO_TITLE} />
        <meta property="og:image" content={SEO_IMAGE} />
        <meta property="og:url" content={SEO_URL} />
        <meta property="og:site_name" content={SEO_TITLE} />
        <meta property="og:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:title" content={SEO_TITLE} />
        <meta name="twitter:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:image" content={SEO_IMAGE} />
        <meta name="twitter:url" content={SEO_URL} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@idindrakusuma" />

        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Mate+SC&family=Rouge+Script&family=Dancing+Script:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div id="page" css={styDesktopView}>
        <Box className="desktop-view__left-side">{isDesktopView && <DesktopView />}</Box>
        <Box className="desktop-view__right-side">
          <Box boxShadow="2xl">{children}</Box>
        </Box>
      </div>
    </Fragment>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};

export default MainLayout;
