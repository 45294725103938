export const BG_AYAT = `https://ik.imagekit.io/josantoso/dio-anti/ayat.jpg?updatedAt=1701849902821`;
export const BG_COVER = `https://ik.imagekit.io/josantoso/dio-anti/cover.jpg?updatedAt=1701849902805`;
export const BG_WEDDING = `https://ik.imagekit.io/josantoso/dio-anti/wedding.jpg?updatedAt=1701849906740`;
export const BG_GIFTCARD = `https://ik.imagekit.io/josantoso/dio-anti/dekstop-2.jpg?updatedAt=1701849902919`;
export const BG_COUNTING = `https://ik.imagekit.io/invitatoid/template-larasandi/bg-giftcard_9nYWgglcH.jpg?updatedAt=1638247629131`;
export const BG_WISHLIST = `https://ik.imagekit.io/josantoso/dio-anti/bg-wihslist.jpg?updatedAt=1702333869848`;
export const BG_COUPLE = `https://user-images.githubusercontent.com/10141928/149668949-bfb5ce3a-fb7a-4677-9d84-192456f0d50a.png`;
export const BG_FOOTER = `https://ik.imagekit.io/josantoso/dio-anti/footer.jpg?updatedAt=1701849902882`;
export const BG_RSVP = `https://ik.imagekit.io/josantoso/dio-anti/rsvp.jpg?updatedAt=1701849902875`;
export const BG_LOVESTORY = `https://ik.imagekit.io/josantoso/dio-anti/s2.jpg?updatedAt=1701849906566`;
export const BG_FILTER = `https://ik.imagekit.io/josantoso/dio-anti/filter.jpg?updatedAt=1701849975754`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/josantoso/dio-anti/dekstop.jpg?updatedAt=1701849902824`;

// THE BRIDE PHOTOS
export const IMG_MAN = `https://ik.imagekit.io/josantoso/dio-anti/man.jpg?updatedAt=1701850473564`;
export const IMG_GIRL = `https://ik.imagekit.io/josantoso/dio-anti/girl.jpg?updatedAt=1701850473464`;
export const COVER_LOVE_STORY = `https://ik.imagekit.io/josantoso/dio-anti/dekstop.jpg?updatedAt=1701849902824`;

// COVER GALLERY
export const COVER_GALLERY_1 = `https://ik.imagekit.io/josantoso/dio-anti/gallery.jpg?updatedAt=1701849902824`;
export const COVER_GALLERY_2 = `https://ik.imagekit.io/invitatoid/template-larasandi/slide_2_5teyVb9L5.jpg?updatedAt=1638248000325`;

// IMAGE FADE (SLIDE SHOW)
export const IMG_SLIDE_1 = `https://ik.imagekit.io/josantoso/dio-anti/s1.jpg?updatedAt=1701849906088`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/josantoso/dio-anti/s2.jpg?updatedAt=1701849906566`;
export const IMG_SLIDE_3 = `https://ik.imagekit.io/josantoso/dio-anti/s3.jpg?updatedAt=1701849906183`;
export const IMG_SLIDE_4 = `https://ik.imagekit.io/josantoso/dio-anti/s4.jpg?updatedAt=1701849906139`;
export const IMG_SLIDE_5 = `https://ik.imagekit.io/josantoso/dio-anti/s5.jpg?updatedAt=1701849906233`;

// INVITATO GLOBAL ASSETS
export const INVITATO_LOGO = `https://lh3.googleusercontent.com/-zCJo2-mf_4A/YZ-Ovftkr0I/AAAAAAAAABw/s2v0OGabAmA_0YtFckCFBNBWnFB_NSydQCLcBGAsYHQ/image.png`;

// DON'T CHANGE USING CDN IMAGES
import ImgQRCover from '@/images/qrcover.jpg';
export const IMG_QR_COVER = ImgQRCover;