import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

const TEXT_SHADOW = 'rgba(12,12,12,.8)';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      bgPos="center"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      textShadow={`1px 1px 4px ${TEXT_SHADOW}`}
    >
      <Box bgColor={BG_SECONDARY_TRANSPARENT} height="100%" padding="42px">
        <WithAnimation>
          <Text color="white" letterSpacing="2px" fontSize="md" fontWeight="light">
            {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            margin="32px 0"
            fontWeight="normal"
            size="4xl"
            letterSpacing="2px"
            color="white"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text
            color="white"
            fontWeight="light"
            fontStyle="italic"
            maxWidth="500px"
            letterSpacing="1px"
          >
            {`“I love thee with a love that shall not die, till the sun grows cold and the stars grow old.”`}{' '}
            <br />— William Shakespeare
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
